<script>
import Layout from "../../layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import axios from "axios";
import appConfig from "../../../../src/app.config";

export default {
  locales: {
    pt: {
      "My Contracts": "Meus Contratos",

      Send: "Enviar",
      "Choose the file": "Escolha o arquivo",
      Receipts: "Comprovantes",
      "The file has been send": "O arquivo foi enviado com sucesso!",
    },
    es: {
      "My Contracts": "Mis Contratos",

      Send: "Enviar",
      "Choose the file": "Elige el archivo",
      Receipts: "Comprovantes",
      "The file has been send": "El archivo ha sido enviado",
    },
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      contract: {
        loading: true,

        id: this.$route.params.id,
        code: "",
        status: "",
        date: {
          start: "",
          end: "",
        },
        value: "0.00",
      },

      document: {
        loading: false,
        status: false,
        id: null,
        title: null,
        file: null,
      },

      choosed: null,
      file: null,

      proof: {
        add: false,
        loading: false,
        errored: false,
        status: false,
        list: null,
      },
    };
  },
  methods: {
    getContract() {
      api.get("contract/" + this.contract.id).then((response) => {
        if (response.data.status == "success") {
          this.contract = response.data.contract;
          this.contract.loading = false;

          this.getFile()
        }
      });
    },
    getFile: function() {
      this.proof.loading = true;

      api
        .get("store/orders/file/" + this.contract.order.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.proof.list = response.data.files;
          } else {
            this.proof.list = null;
          }
        })
        .catch((error) => {
          this.proof.errored = error;
          this.proof.loading = false;
        })
        .finally(() => {
          this.proof.loading = false;
        });
    },
    setFile() {
      this.document.loading = true;

      let formData = new FormData();

      formData.append('file', this.file);

      var config = {
        headers: {
          'x-auth-token': localStorage.token,
          'Content-Type': 'multipart/form-data',
        },
      };

      axios
        .post(
          appConfig.apiUrl + "store/orders/file/" + this.contract.order.id,
          formData,
          config
        )
        .then((response) => {
          if (response.data.status == "success") {
            this.getFile();
            this.$noty.success(response.data.message);
            this.document.status = false;
          } else {
            this.$noty.error(response.data.message);
            this.document.status = false;
          }
          this.document.loading = false;
        });
    },
    onFileSelected(e) {
      this.file = e.target.files[0]
      this.choosed = this.file.name
    },
  },
  mounted() {
    this.getContract();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("My Contracts") }}</h4>
        </div>
      </div>
    </div>

    <div v-if="contract.loading" class="text-center">
      <b-spinner
        small
        class="ml-2 align-middle"
        variant="dark"
        role="status"
      ></b-spinner>
    </div>
    <div v-else>
      <div class="row justify-content-between">
        <div class="col-12">
          <div class="card contract text-dark">
            <div class="card-body pb-2">
              <div class="row mb-2">
                <div class="col-md-4 contract-code mb-2">
                  <small>CÓDIGO</small><br />
                  #{{ contract.code }}
                </div>
              </div>
              <div class="border-top pt-3 pb-2 row">
                <div class="col-md-4 contract-code mb-2">
                  <small>VALOR</small><br />
                  {{ contract.value | currency }}
                </div>
                <div class="col-md-8 contract-status mb-2">
                  <small>STATUS</small><br />
                  <span
                    v-if="contract.status == 'pending'"
                    class="badge badge-danger p-2 font-size-13 text-uppercase"
                    >Pendente</span
                  >
                  <span
                    v-else-if="contract.status == 'inactive'"
                    class="badge badge-primary p-2 font-size-13 text-uppercase"
                    >Inativo</span
                  >
                  <span
                    v-else-if="contract.status == 'active'"
                    class="badge badge-success p-2 font-size-13 text-uppercase"
                    >Ativo</span
                  >
                  <span
                    v-else-if="contract.status == 'closed'"
                    class="badge badge-dark p-2 font-size-13 text-uppercase"
                    >Encerrado</span
                  >
                  <span
                    v-else-if="contract.status == 'deleted'"
                    class="badge badge-danger p-2 font-size-13 text-uppercase"
                    >Cancelado</span
                  >
                </div>
              </div>
              <div class="border-top pt-3 row mb-1">
                <div class="col-md-4 contract-code mb-2">
                  <small>STATUS DA LICENÇA</small><br />
                  <span
                    v-if="contract.license.status == 'pending'"
                    class="badge badge-warning p-2 font-size-13 text-uppercase"
                    >Pendente</span
                  >
                  <span
                    v-else-if="contract.license.status == 'available'"
                    class="badge badge-soft-info p-2 font-size-13 text-uppercase"
                    >Disponível para uso</span
                  >
                  <span
                    v-else-if="contract.license.status == 'used'"
                    class="badge badge-soft-success p-2 font-size-13 text-uppercase"
                    >Utilizado</span
                  >
                </div>
                <div class="col-md-8 contract-license mb-2">
                  <small>CHAVE DE LICENÇA</small><br />
                  <template v-if="contract.license.status == 'pending'">
                    <span
                      class="badge badge-soft-default p-2 font-size-13 text-uppercase"
                      >–</span
                    >
                  </template>
                  <template v-else>
                    <span
                      class="badge badge-soft-default p-2 font-size-13 text-uppercase"
                    >
                      {{ contract.license.key }}
                    </span>
                    <button
                      class="btn btn-link p-0 ml-2"
                      v-clipboard:copy="contract.license.key"
                      v-on:click="
                        $noty.success(
                          'A chave de licença foi copiada com sucesso.'
                        )
                      "
                    >
                      <i
                        class="bx bx-copy font-size-17 text-dark align-top"
                      ></i>
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="contract.status == 'pending'"
            class="card contract text-dark"
          >
            <div class="card-body">
              <b-tabs
                justified
                nav-class="nav-tabs-custom"
                content-class="text-muted"
              >
                <b-tab v-for="(pay, method) in contract.payments" :key="method">
                  <template v-slot:title>{{ pay.name }}</template>
                  <div v-if="method == 'pix'" class="text-center">
                    <img style="width: 250px;" :src="pay.qrcode" />
                    <p>Leia o qrcode acima ou</p>
                    <ol class="text-left">
                      <li>Abra o app do seu banco ou seu app de pagamentos.</li>
                      <li>Busque a opção de pagar com pix.</li>
                      <li>Copie e cole o seguinte código.</li>
                      <li>
                        Após o pagamento envie o comprovante da tranferência.
                      </li>
                    </ol>

                    <div class="border rounded p-3 mb-3 text-left">
                      {{ pay.copypaste }}
                    </div>

                    <button
                      class="btn btn-default"
                      v-clipboard:copy="pay.copypaste"
                    >
                      <i class="bx bx-copy font-size-18 align-middle"></i>
                      Copiar
                    </button>

                    <hr />

                    <div class="mt-5 text-center">
                      <h3 class="font-size-16 mb-2">Comprovantes</h3>
                      <p>
                        Após realizar seu pagamento, anexe seu comprovante aqui.
                      </p>
                      <div
                        class="pb-3"
                        v-for="(prof, index) in proof.list"
                        :key="index"
                      >
                        <a target="_blank" :href="prof.url"
                          >
                          {{ prof.url.replace("https://s3.us-west-2.amazonaws.com/s3.udyatcorp.com/store/", "") }}</a
                        >
                      </div>
                      <div class="text-center p-0 row justify-content-center mb-5">
                        <b-form @submit.prevent="setFile" class="col-m-8 col-lg-6">
                          <div class="input-group">
                            <input
                              type="file"
                              id="file"
                              ref="file"
                              aria-describedby="inputGroupFileAddon04"
                              @change="onFileSelected"
                              aria-label="Upload"
                              class="form-control"
                            />
                            <b-button
                              class="border-left-0 px-4"
                              style="border-top-left-radius: 0; border-bottom-left-radius: 0;"
                              type="submit"
                              variant="default"
                              :disabled="document.loading"
                            >
                              {{ t("Send") }}
                              <b-spinner
                                v-if="document.loading"
                                small
                                class="ml-2 align-middle"
                                variant="dark"
                                role="status"
                              ></b-spinner>
                            </b-button>
                          </div>
                        </b-form>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.contract {
  border-left: solid 5px #010101;
}

.table-profit {
  line-height: 15px;
}
.table-profit th,
.table-profit td {
  white-space: nowrap;
}
.table-year th {
  background: #bebfc1;
}
.table-month th {
  background: #d2d3d4;
}

.token input {
  margin: 0 2px;
  width: 40px;
  height: 40px;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}
</style>
